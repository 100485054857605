import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export const defaultParams = Object.freeze({
  currentPage: 1,
  itemsPerPage: 100,
  status: 1,
  sort: '1_asc',
})
export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    courseYears: [],
    courseSemesters: [],
    departments: [],
    majors: [],
    majorCodes: [],
    subjects: [],
    creditClasses: [],
    creditClassStartDates: [],
    days: [],
    turns: [],
    rooms: [],
    creditClassRooms: [],
    teachers: [],
    classes: [],
    studentClasses: [],
    students: [],
    consultants: [],
    creditClassRoomByCreditClass: [],
    creditClassTeachers: [],
    buildings: [],
    programmes: [],
    programmeSubjects: [],
    titles: [],
    programmeSubjectGroups: [],
    articleCategories: [],
    teachersByCreditClass: [],
    schoolYears: [],
    examinationSchedulers: [],
    examinationSchedulerSubjects: [],
    examinationSchedulerSubjectDates: [],
    scholarships: [],
    factDisabilities: [],
    factDistricts: [],
    factEthnics: [],
    factNationalities: [],
    factProvinces: [],
    factReligions: [],
    deductLevels: [],
    organizations: [],
    parentOrganizations: [],
    teacherTeams: [],
    admissionDeductLevels: [],
    allCredentialTypes: [],
    allCertificateBlanks: [],
    credentials: [],
    credentialConditions: [],
    credentialSamples: [],
    certificateProcessTypes: [],
    certificateImplements: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    courseYears: state => state.courseYears,
    courseSemesters: state => state.courseSemesters,
    departments: state => state.departments,
    majors: state => state.majors,
    majorCodes: state => state.majorCodes,
    subjects: state => state.subjects,
    creditClasses: state => state.creditClasses,
    creditClassStartDates: state => state.creditClassStartDates,
    days: state => state.days,
    turns: state => state.turns,
    rooms: state => state.rooms,
    creditClassRooms: state => state.creditClassRooms,
    classes: state => state.classes,
    studentClasses: state => state.studentClasses,
    teachers: state => state.teachers,
    consultants: state => state.consultants,
    students: state => state.students,
    creditClassRoomByCreditClass: state => state.creditClassRoomByCreditClass,
    creditClassTeachers: state => state.creditClassTeachers,
    buildings: state => state.buildings,
    programmes: state => state.programmes,
    programmeSubjects: state => state.programmeSubjects,
    titles: state => state.titles,
    programmeSubjectGroups: state => state.programmeSubjectGroups,
    articleCategories: state => state.articleCategories,
    teachersByCreditClass: state => state.teachersByCreditClass,
    schoolYears: state => state.schoolYears,
    examinationSchedulers: state => state.examinationSchedulers,
    examinationSchedulerSubjects: state => state.examinationSchedulerSubjects,
    examinationSchedulerSubjectDates: state => state.examinationSchedulerSubjectDates,
    scholarships: state => state.scholarships,
    factDisabilities: state => state.factDisabilities,
    factDistricts: state => state.factDistricts,
    factEthnics: state => state.factEthnics,
    factNationalities: state => state.factNationalities,
    factProvinces: state => state.factProvinces,
    factReligions: state => state.factReligions,
    deductLevels: state => state.deductLevels,
    organizations: state => state.organizations,
    parentOrganizations: state => state.parentOrganizations,
    teacherTeams: state => state.teacherTeams,
    admissionDeductLevels: state => state.admissionDeductLevels,
    allCredentialTypes: state => state.allCredentialTypes,
    allCertificateBlanks: state => state.allCertificateBlanks,
    credentials: state => state.credentials,
    credentialConditions: state => state.credentialConditions,
    credentialSamples: state => state.credentialSamples,
    certificateProcessTypes: state => state.certificateProcessTypes,
    certificateImplements: state => state.certificateImplements,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
    SET_COURSES: (state, records) => {
      state.courses = records
    },
    SET_COURSE_YEARS: (state, records) => {
      state.courseYears = records
    },
    SET_COURSE_SEMESTERS: (state, records) => {
      state.courseSemesters = records
    },
    SET_DEPARTMENTS: (state, records) => {
      state.departments = records
    },
    SET_MAJORS: (state, records) => {
      state.majors = records
    },
    SET_MAJOR_CODES: (state, records) => {
      state.majorCodes = records
    },
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
    SET_DAYS: (state, records) => {
      state.days = records
    },
    SET_TURNS: (state, records) => {
      state.turns = records
    },
    SET_ROOMS: (state, records) => {
      state.rooms = records
    },
    SET_CREDIT_CLASS_ROOMS: (state, records) => {
      state.creditClassRooms = records
    },
    SET_CLASSES: (state, records) => {
      state.classes = records
    },
    SET_STUDENT_CLASSES: (state, records) => {
      state.studentClasses = records
    },
    SET_TEACHERS: (state, records) => {
      state.teachers = records
    },
    SET_CONSULTANTS: (state, records) => {
      state.consultants = records
    },
    SET_STUDENTS: (state, records) => {
      state.students = records
    },
    SET_CREDIT_CLASSES: (state, records) => {
      state.creditClasses = records
    },
    SET_CREDIT_CLASS_START_DATES: (state, records) => {
      state.creditClassStartDates = records
    },
    SET_CREDIT_CLASSES_ROOMS: (state, records) => {
      state.creditClassRoomByCreditClass = records
    },
    SET_BUILDINGS: (state, records) => {
      state.buildings = records
    },
    SET_PROGRAMMES: (state, records) => {
      state.programmes = records
    },
    SET_PROGRAMME_SUBJECTS: (state, records) => {
      state.programmeSubjects = records
    },
    SET_TITLES: (state, records) => {
      state.titles = records
    },
    SET_CREDIT_CLASS_TEACHERS: (state, records) => {
      state.creditClassTeachers = records
    },
    SET_PROGRAMME_SUBJECT_GROUPS: (state, records) => {
      state.programmeSubjectGroups = records
    },
    SET_PORTAL_CATEGORIES: (state, records) => {
      state.articleCategories = records
    },
    SET_TEACHERS_BY_CREDIT_CLASS: (state, records) => {
      state.teachersByCreditClass = records
    },
    SET_SCHOOL_YEARS: (state, records) => {
      state.schoolYears = records
    },
    SET_EXAMINATION_SCHEDULERS: (state, records) => {
      state.examinationSchedulers = records
    },
    SET_EXAMINATION_SCHEDULER_SUBJECTS: (state, records) => {
      state.examinationSchedulerSubjects = records
    },
    SET_EXAMINATION_SCHEDULER_SUBJECT_DATES: (state, records) => {
      state.examinationSchedulerSubjectDates = records
    },
    SET_SCHOLARSHIPS: (state, records) => {
      state.scholarships = records
    },
    SET_FACT_DISABILITIES: (state, records) => {
      state.factDisabilities = records
    },
    SET_FACT_DISTRICTS: (state, records) => {
      state.factDistricts = records
    },
    SET_FACT_ETHNICS: (state, records) => {
      state.factEthnics = records
    },
    SET_FACT_NATIONALITIES: (state, records) => {
      state.factNationalities = records
    },
    SET_FACT_PROVINCES: (state, records) => {
      state.factProvinces = records
    },
    SET_FACT_RELIGIONS: (state, records) => {
      state.factReligions = records
    },
    SET_DEDUCT_LEVELS: (state, records) => {
      state.deductLevels = records
    },
    SET_ORGANIZATIONS: (state, records) => {
      state.organizations = records
    },
    SET_PARENT_ORGANIZATIONS: (state, records) => {
      state.parentOrganizations = records
    },
    SET_ADMISSION_DEDUCT_LEVELS: (state, records) => {
      state.admissionDeductLevels = records
    },

    SET_TEACHER_TEAMS: (state, records) => {
      state.teacherTeams = records
    },
    SET_ALL_CREDENTIAL_TYPES: (state, records) => {
      state.allCredentialTypes = records
    },
    SET_ALL_CERTIFICATE_BLANKS: (state, records) => {
      state.allCertificateBlanks = records
    },
    SET_CREDENTIALS: (state, records) => {
      state.credentials = records
    },
    SET_CREDENTIAL_CONDITIONS: (state, records) => {
      state.credentialConditions = records
    },
    SET_CREDENTIAL_SAMPLES: (state, records) => {
      state.credentialSamples = records
    },
    SET_CERTIFICATE_PROCESS_TYPES: (state, records) => {
      state.certificateProcessTypes = records
    },
    SET_CERTIFICATE_IMPLEMENTS: (state, records) => {
      state.certificateImplements = records
    },
  },
  actions: {
    async getCertificateImplements({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate_implements', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_CERTIFICATE_IMPLEMENTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCertificateProcessType({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate-process-types', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_CERTIFICATE_PROCESS_TYPES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourseSemestersByCourseId({commit}, params){
      try {
        const response = await axios.get('certificates/course_semester', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.code,
            yearId: item.yearId,
          }))
          commit('SET_COURSE_SEMESTERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAllCredentials({ commit }, params){
      try {
        const response = await axios.get('certificates/credentials', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDENTIALS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCredentialAllConditions({commit}, params){
      try {
        const response = await axios.get('certificates/credential_conditions', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDENTIAL_CONDITIONS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCredentialAllSamples({commit}, params){
      try {
        const response = await axios.get('certificates/credential_samples', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDENTIAL_SAMPLES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAllCertificateBlanks({ commit }){
      try {
        const response = await axios.get('certificates/certificate-blanks/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_ALL_CERTIFICATE_BLANKS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getAllCredentialTypes({ commit }){
      try {
        const response = await axios.get('certificates/credential_types/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.description,
            code: item.code ? item.code : null,
          }))
          commit('SET_ALL_CREDENTIAL_TYPES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getScholarships({ commit }, params) {
      try {
        const response = await axios.get('uni/factScholarships', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_SCHOLARSHIPS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCreditClassRoomsByCreditClass({ commit }, parameters) {
      try {
        const params = {
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassRoomByCreditClass, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDIT_CLASSES_ROOMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystems({ commit }, parameters) {
      try {
        const ownParams = { organizationId: null, trainingFormId: '', sort: '1_asc' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.trainingSystem, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TRAINING_SYSTEMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, parameters) {
      try {
        const ownParams = { trainingSystemId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.course}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_COURSES', mapData)
          const mapDataYear = records.map(item => ({
            value: item.id,
            label: item.startDate.substring(0, 4),
          }))
          commit('SET_COURSE_YEARS', mapDataYear)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourseSemesters({ commit }, parameters) {
      try {
        const ownParams = { courseId: '', status: 1 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.courseSemester}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_COURSE_SEMESTERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourseSemestersNew({ commit }, parameters) {
      try {
        const ownParams = { courseId: '', status: 1 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.courseSemester}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.name,
            status: item.status,
          }))
          commit('SET_COURSE_SEMESTERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getDepartments({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '', leaderId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.department, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_DEPARTMENTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMajors({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.major}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_MAJORS', mapData)
          const mapDataCode = records.map(item => ({
            value: item.id,
            label: item.code,
          }))
          commit('SET_MAJOR_CODES', mapDataCode)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getSubjects({ commit }, parameters) {
      try {
        const ownParams = {
          subjectTypeId: null,
          departmentId: '',
          programmeId: '',
          itemsPerPage: 1000,
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.subject, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          // const mapData = mapDropdown(records)
          const mapData = records.map(item => ({
            value: item.id,
            label: `${item.name} [${item.code}]`,
            shouldAddPracticeClass: item.shouldAddPracticeClass,
            code: item.code ? item.code : null,
          }))
          commit('SET_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getClasses({ commit }, parameters) {
      try {
        const ownParams = { majorId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.class, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          // const mapData = mapDropdown(records)
          const mapData = records.map(item => ({
            value: item.id,
            label: item.name,
            capacity: item.capacity,
          }))
          commit('SET_CLASSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getRooms({ commit }, parameters) {
      try {
        const ownParams = { buildingId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.rooms, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_ROOMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCreditClassRooms({ commit }, parameters) {
      try {
        const params = {
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassRoom, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDIT_CLASS_ROOMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTeachers({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.teacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTeacherTeams({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.teacherTeams, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TEACHER_TEAMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getConsultants({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.teacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CONSULTANTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDays({ commit }) {
      try {
        const response = await axios.get(ApiExtRoutes.days)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          const mapData = mapDropdown(records)
          commit('SET_DAYS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTurns({ commit }) {
      try {
        const response = await axios.get(ApiExtRoutes.turns)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          const mapData = mapDropdown(records)
          commit('SET_TURNS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getStudentClasses({ commit }, parameters) {
      try {
        const ownParams = { classId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.student, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.studentClassId,
            label: item.name,
          }))
          commit('SET_STUDENT_CLASSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCreditClasses({ commit }, parameters) {
      try {
        const ownParams = { courseSemesterId: '', subjectId: '', status: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClass, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.id,
            label: item.code,
          }))
          const mapData2 = records.map(item => ({
            value: item.id,
            label: item.startDate,
          }))
          commit('SET_CREDIT_CLASSES', mapData)
          commit('SET_CREDIT_CLASS_START_DATES', mapData2)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getBuildings({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.building, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_BUILDINGS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgrammes({ commit }, parameters) {
      try {
        const ownParams = {
          organizationId: '',
          courseId: '',
          majorId: '',
          subjectId: '',
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.programme, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_PROGRAMMES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgrammeSubjects({ commit }, parameters) {
      try {
        const ownParams = {
          organizationId: '',
          courseId: '',
          subjectId: '',
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.programmeSubject, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.subjectId,
            label: item.name,
          }))
          commit('SET_PROGRAMME_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTitles({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.title, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TITLES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCreditClassTeachers({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassTeacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_CREDIT_CLASS_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeacherByCreditClass({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.creditClassTeacher, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.teacherId,
            label: item.name,
          }))
          commit('SET_CREDIT_CLASS_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgrammeSubjectGroups({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.programmeSubjectGroup, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_PROGRAMME_SUBJECT_GROUPS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getArticleCategories({ commit }, params) {
      try {
        const response = await axios.get(ApiExtRoutes.articleCategories, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = mapDropdown(data.data)
          commit('SET_PORTAL_CATEGORIES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachersByCreditClass({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.teachersByCreditClass, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TEACHERS_BY_CREDIT_CLASS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSchoolYears({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.year, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_SCHOOL_YEARS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getExaminationSchedulers({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.examinationScheduler, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_EXAMINATION_SCHEDULERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getExaminationSchedulerSubjects({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.examinationSchedulerSubject, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapOptionalData = records.map(item => ({
            value: item.id,
            label: item.startDate,
            isSeparateClass: item.isSeparateClass,
          }))
          const mapData = mapDropdown(records)

          commit('SET_EXAMINATION_SCHEDULER_SUBJECT_DATES', mapOptionalData)
          commit('SET_EXAMINATION_SCHEDULER_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getFactDisabilities({ commit }) {
      try {
        const response = await axios.get('categories/fact_disabilities')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_FACT_DISABILITIES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getFactDistricts({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get('categories/fact_districts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.code,
            label: item.name,
            provinceCode: item.provinceCode,
          }))
          commit('SET_FACT_DISTRICTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getFactEthnics({ commit }, params) {
      try {
        const response = await axios.get('categories/factEthnics', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.code,
            label: item.name,
          }))
          commit('SET_FACT_ETHNICS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getFactNationalities({ commit }, params) {
      try {
        const response = await axios.get('categories/factNationalities', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_FACT_NATIONALITIES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getFactProvinces({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get('categories/factProvinces', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.code,
            label: item.name,
          }))
          commit('SET_FACT_PROVINCES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getFactReligions({ commit }, params) {
      try {
        const response = await axios.get('categories/factReligions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            value: item.code,
            label: item.name,
          }))
          commit('SET_FACT_RELIGIONS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDeductLevels({ commit }, parameters) {
      try {
        const ownParams = { status: 1 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/deduct/level', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = records.map(item => ({
            id: item.id,
            value: item.value,
            label: item.name,
          }))
          commit('SET_DEDUCT_LEVELS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getOrganization({ commit }, parameters) {
      try {
        const ownParams = { status: 1 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/factOrganizations/selection', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_ORGANIZATIONS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getParentOrganization({ commit }, parameters) {
      try {
        const ownParams = { status: 1, parentId: 0 }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/factOrganizations/selection', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_PARENT_ORGANIZATIONS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionDeductLevels({ commit }) {
      try {
        const response = await axios.get('admissions/admission_deduct_levels')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = data.data.map(item => ({
            id: item.id,
            value: item.value,
            label: item.name,
          }))
          commit('SET_ADMISSION_DEDUCT_LEVELS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
