import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    graduationRegisterRounds: [],
    trainingSystems: [],
    totalRows: 0,
    years: [],
  },
  getters: {
    graduationRegisterRounds: state => state.graduationRegisterRounds,
    totalRows: state => state.totalRows,
    trainingSystems: state => state.trainingSystems,
    years: state => state.years,
  },
  mutations: {
    SET_YEARS: (state, data) => { state.years = data },
    SET_GRADUATION_REGISTER_ROUNDS: (state, { total, records }) => {
      state.totalRows = total
      state.graduationRegisterRounds = records
    },
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
  },
  actions: {
    async getYears({ commit }, params) {
      try {
        const response = await axios.get('categories/factYears/all', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEARS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getGraduationRegisterRounds({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationRegisterRounds', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_GRADUATION_REGISTER_ROUNDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createGraduationRegisterRound({ commit }, params) {
      try {
        const response = await axios.post('uni/graduationRegisterRounds', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateGraduationRegisterRound({ commit }, params) {
      try {
        const response = await axios.put(`uni/graduationRegisterRounds/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteGraduationRegisterRound({ commit }, id) {
      try {
        const response = await axios.delete(`uni/graduationRegisterRounds/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
