import axios from '@/libs/axios'
import { handleCatch } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async downloadWord(params) {
    try {
      const response = await axios.get('uni/tnhReports/reportStudentManagementRegister', { params, responseType: 'blob' })
      if (response.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      handleCatch(e)
    }
  },
}
