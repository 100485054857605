export const ApiCode = Object.freeze({
  SUCCESS: 1,
  TOKEN_EXPIRED: 8,
})
const maxOrderNoPath = '/max/order-no/'
export const ApiBaseRoutes = Object.freeze({
  creditClass: 'uni/credit_classes',
  creditClassTeacher: 'uni/credit_class/teachers',
  creditClassStudent: 'uni/credit_class/students',
  programme: 'uni/programmes',
  programmeSubject: 'uni/programme/subjects',
  trainingSystem: 'uni/trainingSystems',
  course: 'uni/courses',
  courseSemester: 'uni/course/semesters',
  department: 'uni/departments',
  major: 'uni/majors',
  subject: 'uni/subjects',
  days: 'uni/days/get_all_day',
  turns: 'uni/turns/get_all_turn',
  rooms: 'uni/rooms',
  creditClassRoom: 'uni/credit_class_rooms',
  teachers: 'uni/teachers',
  class: 'uni/classes',
  teacher: 'uni/teachers',
  student: 'uni/students',
  studentClass: 'uni/studentClasses',
  report: 'uni/report',
  building: 'uni/buildings',
  programmeSubjectGroup: 'uni/programme/subjects/groups',
  articleCategories: 'uni/article_categories/list',
  diploma: 'uni/diplomas',
  notification: 'uni/notifications',
  systemParameter: 'uni/parameters',
  examinationScheduler: 'uni/examination_schedulers',
  studentTransfer: 'uni/transfers',
  studentSuspend: 'uni/studentCgpa/suspends',
  maintenance: 'uni/maintenances',
  deductLevel: 'uni/deduct/level',
  scoringGeneral: 'uni/general-score',
  scoringComponent: 'uni/component-score',
  studentStatus: 'categories/fact_student_status',
  studentCreditClassStatus: 'categories/fact_student_credit_class_status',
  researchInventionType: 'uni/fact_research_invention_type',
  ward: 'categories/fact_ward',
  articleCategory: 'uni/article_categories',
  infringe: 'uni/factInfringes',
  year: 'categories/factYears',
  title: 'uni/factTitles',
  scholarship: 'uni/factScholarships',
  organization: 'uni/factOrganizations',
  carouse: 'uni/carousels',
  trainingForm: 'uni/trainingForms',
  chart: 'uni/charts',
  departmentType: 'uni/departmentType',
  academicRank: 'uni/factAcademicRanks',
  academicDegree: 'uni/factAcademicDegrees',
  majorApproval: 'uni/majorApprovals',
  factAdmissionFormTypes: 'uni/factAdmissionFormTypes',
  factAdmissionSubjects: 'uni/factAdmissionSubjects',
  factAdmissionBlocks: 'uni/factAdmissionBlocks',
  factAdmissionRoles: 'uni/factAdmissionRoles',
  factAdmissionSubjectTypes: 'uni/factAdmissionSubjectTypes',
  factAdmissionConducts: 'uni/factAdmissionConducts',
  factAdmissionCriteriaTypes: 'uni/factAdmissionCriteriaTypes',
  factAdmissionStudentTypes: 'uni/factAdmissionStudentTypes',
  factAdmissionViolateTypes: 'uni/factAdmissionViolateTypes',
  factAdmissionDisciplines: 'uni/factAdmissionDisciplines',
  factAdmissionCertificateTypes: 'uni/factAdmissionCertificateTypes',
  factAdmissionConfirmationTypes: 'uni/factAdmissionConfirmationTypes',
  factAdmissionLanguageCertificateTypes: 'uni/factAdmissionLanguageCertificateTypes',
  factPriorityZones: 'uni/factPriorityZones',
  factHighSchools: 'categories/factHighSchools',
  factAcademicPerformances: 'uni/factAcademicPerformances',
  factAdmissionScoreTypes: 'uni/factAdmissionScoreTypes',
  evaluateObjectGroups: 'surveys/evaluateObjectGroups',
  evaluatedObjectGroups: 'surveys/evaluatedObjectGroups',
  evaluationCriterias: 'surveys/evaluationCriterias',
  setOfCriteriaObjects: 'surveys/setOfCriteriaObjects',
  setOfCriterias: 'surveys/setOfCriterias',
  evaluateManagement: 'surveys/evaluateManagement',
  setOfEvaluateLevels: 'surveys/setOfEvaluateLevels',
  setOfCriteriaTypes: 'surveys/setOfCriteriaTypes',
  employeeEvaluate: 'surveys/employeeEvaluate',
  studentEvaluate: 'surveys/getDataEvaluate',
  teacherTeams: 'uni/teacherTeams',
  configStudentCode: 'uni/configStudentCode',
})
export const ApiExtRoutes = Object.freeze({
  creditClassMaxOrderNo: `${ApiBaseRoutes.creditClass}${maxOrderNoPath}`,
  creditClassTeacherMaxOrderNo: `${ApiBaseRoutes.creditClassTeacher}${maxOrderNoPath}`,
  creditClassStudentMaxOrderNo: `${ApiBaseRoutes.creditClassStudent}${maxOrderNoPath}`,
  courseSemesterMaxOrderNo: `${ApiBaseRoutes.courseSemester}${maxOrderNoPath}`,
  studentClassMaxOrderNo: `${ApiBaseRoutes.studentClass}${maxOrderNoPath}`,
  programmeSubjectGroupMaxOrderNo: `${ApiBaseRoutes.programmeSubjectGroup}${maxOrderNoPath}`,
  systemParameterMaxOrderNo: `${ApiBaseRoutes.systemParameter}${maxOrderNoPath}`,
  creditClassCopy: `${ApiBaseRoutes.creditClass}/copy-class/`,
  planCreditClass: `${ApiBaseRoutes.creditClass}/plan_by_programme/`,
  bulkApproveCreditClass: `${ApiBaseRoutes.creditClass}/bulk_approve/`,
  reportPointByCreditClass: `${ApiBaseRoutes.report}/credit-class`,
  creditClassRoomByCreditClass: `${ApiBaseRoutes.creditClassRoom}/credit_class`,
  allCourseSemester: `${ApiBaseRoutes.courseSemester}/all`,
  teachersByCreditClass: `${ApiBaseRoutes.teacher}/credit_class`,
  planClass: `${ApiBaseRoutes.class}/plan_by_programme`,
  planClassByDiploma: `${ApiBaseRoutes.class}/plan_by_diploma`,
  diplomaStudent: `${ApiBaseRoutes.diploma}/students`,
  diplomaPlanStudentClass: `${ApiBaseRoutes.diploma}/plan_students`,
  notificationReceiver: `${ApiBaseRoutes.notification}/receivers`,
  examinationSchedulerSubject: `${ApiBaseRoutes.examinationScheduler}/subjects`,
  studentTransferDetail: `${ApiBaseRoutes.studentTransfer}/subjects`,
  replaceThesisSubject: `${ApiBaseRoutes.programmeSubject}/replace-thesis`,
  maintenanceScheduler: `${ApiBaseRoutes.maintenance}/schedulers`,
  maintenanceBackup: `${ApiBaseRoutes.maintenance}/backups`,
  maintenanceRecovery: `${ApiBaseRoutes.maintenance}/recoveries`,
  scoringGeneralMaxOrderNo: `${ApiBaseRoutes.scoringGeneral}${maxOrderNoPath}`,
  scoringComponentMaxOrderNo: `${ApiBaseRoutes.scoringComponent}${maxOrderNoPath}`,
  studentStatusMaxOrderNo: `${ApiBaseRoutes.studentStatus}${maxOrderNoPath}`,
  studentCreditClassStatusMaxOrderNo: `${ApiBaseRoutes.studentCreditClassStatus}${maxOrderNoPath}`,
  researchInventionTypeMaxOrderNo: `${ApiBaseRoutes.researchInventionType}${maxOrderNoPath}`,
  wardMaxOrderNo: `${ApiBaseRoutes.ward}${maxOrderNoPath}`,
  articleCategoryMaxOrderNo: `${ApiBaseRoutes.articleCategory}${maxOrderNoPath}`,
  infringeMaxOrderNo: `${ApiBaseRoutes.infringe}${maxOrderNoPath}`,
  titleMaxOrderNo: `${ApiBaseRoutes.title}${maxOrderNoPath}`,
  scholarshipMaxOrderNo: `${ApiBaseRoutes.scholarship}${maxOrderNoPath}`,
  yearMaxOrderNo: `${ApiBaseRoutes.year}${maxOrderNoPath}`,
  organizationMaxOrderNo: `${ApiBaseRoutes.organization}${maxOrderNoPath}`,
  carouseMaxOrderNo: `${ApiBaseRoutes.carouse}${maxOrderNoPath}`,
  trainingFormMaxOrderNo: `${ApiBaseRoutes.trainingForm}${maxOrderNoPath}`,
  departmentTypeMaxOrderNo: `${ApiBaseRoutes.departmentType}${maxOrderNoPath}`,
  academicRankMaxOrderNo: `${ApiBaseRoutes.academicRank}${maxOrderNoPath}`,
  academicDegreeMaxOrderNo: `${ApiBaseRoutes.academicDegree}${maxOrderNoPath}`,
  majorApprovalMaxOrderNo: `${ApiBaseRoutes.majorApproval}${maxOrderNoPath}`,
  majorApprovalSpecialize: `${ApiBaseRoutes.majorApproval}/specialize`,
  majorApprovalSpecializeMaxOrderNo: `${ApiBaseRoutes.majorApproval}/specialize/max/order-no/`,
  majorApprovalStudent: `${ApiBaseRoutes.majorApproval}/student`,
  majorApprovalStudentChoice: `${ApiBaseRoutes.majorApproval}/student/choice`,
  majorApprovalStudentChoiceMaxOrderNo: `${ApiBaseRoutes.majorApproval}/student/choice/max/order-no/`,
  evaluationCriteriaMaxOrderNo: `${ApiBaseRoutes.evaluationCriterias}${maxOrderNoPath}`,
  factAdmissionFormTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionFormTypes}${maxOrderNoPath}`,
  factAdmissionSubjectsMaxOrderNo: `${ApiBaseRoutes.factAdmissionSubjects}${maxOrderNoPath}`,
  factAdmissionBlocksMaxOrderNo: `${ApiBaseRoutes.factAdmissionBlocks}${maxOrderNoPath}`,
  factAdmissionRolesMaxOrderNo: `${ApiBaseRoutes.factAdmissionRoles}${maxOrderNoPath}`,
  factAdmissionSubjectTypeMaxOrderNo: `${ApiBaseRoutes.factAdmissionSubjectTypes}${maxOrderNoPath}`,
  factAdmissionConductsMaxOrderNo: `${ApiBaseRoutes.factAdmissionConducts}${maxOrderNoPath}`,
  factAdmissionCriteriaTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionCriteriaTypes}${maxOrderNoPath}`,
  factAdmissionStudentTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionStudentTypes}${maxOrderNoPath}`,
  factAdmissionViolateTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionViolateTypes}${maxOrderNoPath}`,
  factAdmissionDisciplinesMaxOrderNo: `${ApiBaseRoutes.factAdmissionDisciplines}${maxOrderNoPath}`,
  factAdmissionCertificateTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionCertificateTypes}${maxOrderNoPath}`,
  factAdmissionConfirmationTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionConfirmationTypes}${maxOrderNoPath}`,
  factAdmissionLanguageCertificateTypesMaxOrderNo: `${ApiBaseRoutes.factAdmissionLanguageCertificateTypes}${maxOrderNoPath}`,
  factPriorityZonesMaxOrderNo: `${ApiBaseRoutes.factPriorityZones}${maxOrderNoPath}`,
  factHighSchoolsMaxOrderNo: `${ApiBaseRoutes.factHighSchools}${maxOrderNoPath}`,
  factAcademicPerformancesMaxOrderNo: `${ApiBaseRoutes.factAcademicPerformances}${maxOrderNoPath}`,
  factAdmissionScoreTypeMaxOrderNo: `${ApiBaseRoutes.factAdmissionScoreTypes}${maxOrderNoPath}`,
  ...ApiBaseRoutes,
})
export default { ApiCode, ApiBaseRoutes, ApiExtRoutes }
