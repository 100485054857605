import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { YEARS } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.reportAvgStudentClass,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    dataListsTNH: [],
    allYears: YEARS,
    allTrainingSystems: [],
    listTrainingSystemCourses: [],
    listCourseMajors: [],
    listCourseMajorClasses: [],
    listYearSemesters: [],
    listSubjectsOrder: undefined,
    listSubjectsOrderTNH: undefined,
    dataExport: null,
  },
  getters: {
    dataLists: state => state.dataLists,
    dataListsTNH: state => state.dataListsTNH,
    allYears: state => state.allYears,
    allTrainingSystems: state => state.allTrainingSystems,
    listTrainingSystemCourses: state => state.listTrainingSystemCourses,
    listCourseMajors: state => state.listCourseMajors,
    listCourseMajorClasses: state => state.listCourseMajorClasses,
    listYearSemesters: state => state.listYearSemesters,
    listSubjectsOrder: state => state.listSubjectsOrder,
    listSubjectsOrderTNH: state => state.listSubjectsOrderTNH,
    dataExport: state => state.dataExport,
  },
  mutations: {
    SET_ALLTRAININGSYSTEMS: (state, { data }) => {
      state.allTrainingSystems = data
    },
    SET_LISTTRAININGSYSTEMCOURSES: (state, { data }) => {
      state.listTrainingSystemCourses = data
    },
    SET_LISTCOURSEMAJORS: (state, { data }) => {
      state.listCourseMajors = data
    },
    SET_LISTCOURSEMAJORCLASSES: (state, { data }) => {
      state.listCourseMajorClasses = data
    },
    SET_LISTYEARSEMESTERS: (state, { data }) => {
      state.listYearSemesters = data
    },
    SET_LISTSUBJECTSORDER: (state, { data }) => {
      state.listSubjectsOrder = data
    },
    SET_LISTSUBJECTSORDER_TNH: (state, { data }) => {
      state.listSubjectsOrderTNH = data
    },
    SET_DATA: (state, { data }) => {
      state.dataLists = data
    },
    SET_DATA_TNH: (state, { data }) => {
      state.dataListsTNH = data
    },
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            data: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataTNH({ commit }, params) {
      try {
        const response = await axios.get('uni/tnhReports/reportAvgStudentClassTNH', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_TNH', {
            data: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExport({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportAvgStudentClassExport',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExportTNH({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/tnhReports/reportAvgStudentClassExportTNH',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async begin({ commit }) {
      try {
        const response = await axios.get('uni/exemptions/training_systems')
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_ALLTRAININGSYSTEMS', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListTrainingSystemCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/exemptions/courses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTTRAININGSYSTEMCOURSES', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async resetData({ commit }) {
      try {
        commit('SET_DATA', {
          data: [],
        })
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListCourseMajors({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectCoursesMajors', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTCOURSEMAJORS', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListCourseMajorClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectCoursesMajorsClasses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTCOURSEMAJORCLASSES', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListSubjectsOrder({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectListSubjectsOrder', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTSUBJECTSORDER', {
            data: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListSubjectsOrderTNH({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectListSubjectsOrderTNH', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTSUBJECTSORDER_TNH', {
            data: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListYearSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectSemesterYears', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTYEARSEMESTERS', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
